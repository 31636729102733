import axios from 'axios';
import { getApiUrl } from '../api/api-config';
import { IReleaseNoteItem } from '../components/release-notes/release-note-interfaces';
import { RowSpanType } from '../components/usage-metric-charts/usage-metric-interface';
import * as XLSX from 'xlsx';
import { ColDef, GridOptions } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';

export const getOktaDetails = () => {
  const oktaToken: any = localStorage.getItem('sensing-okta-token');
  const oktaTokenObj = JSON.parse(oktaToken);
  if (oktaTokenObj && oktaTokenObj.accessToken !== undefined) {
    return oktaTokenObj;
  }
  return null;
};

export const getOktaToken = () => {
  const oktaTokenDetails = getOktaDetails();
  if (oktaTokenDetails !== null) {
    return oktaTokenDetails.accessToken.accessToken;
  }
  return '';
};

export function rowSpanning(filteredData: Array<RowSpanType>, spanKey: string = 'userid') {
  let rowSpanCount = 1;
  let rowSpanlastCount = 1;
  let currentUserId = '';
  let metricUserObj = filteredData;

  //This first sorting sort or groups by UserId
  if (spanKey !== 'request_id') {
    filteredData.sort((a: any, b: any) => {
      if (a[spanKey] > b[spanKey]) {
        return 1;
      } else if (a[spanKey] < b[spanKey]) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  //Add a property for Row Span count, while calculating for how many rows to span
  //Map function doesn't have ability to traverse previous object
  for (let i = 0; i < metricUserObj.length; i++) {
    rowSpanlastCount = rowSpanCount;
    if (metricUserObj[i][spanKey] === currentUserId) {
      rowSpanCount++;
    } else if (metricUserObj[i][spanKey] !== currentUserId) {
      rowSpanCount = 1;
    }
    currentUserId = metricUserObj[i][spanKey];
    if (i === 0) {
      metricUserObj[0].row_span_count = rowSpanCount === 1 ? rowSpanlastCount : 1;
    } else if (i === metricUserObj.length - 1 && rowSpanCount > 1) {
      metricUserObj[i - rowSpanlastCount].row_span_count = rowSpanCount;
      /* For last rowspan match, we need to go back to the first match add the span count on above for remainging other just add row span 1 */
      for (let j = 1; j <= rowSpanlastCount; j++) {
        metricUserObj[i - rowSpanlastCount + j].row_span_count = 1;
      }
    } else {
      metricUserObj[i - 1].row_span_count = rowSpanCount === 1 ? rowSpanlastCount : 1;
    }
  }

  //Now we need to bring highest index of rowspan to topmost row, grouped within same userId
  filteredData.sort((a: any, b: any) => {
    if (a[spanKey] === b[spanKey]) {
      if (a.row_span_count > b.row_span_count) {
        return -1;
      } else if (a.row_span_count < b.row_span_count) {
        return 1;
      } else {
        return 0;
      }
    }
    return 0;
  });

  return filteredData;
}

export function getUserDetailsFromOktaToken() {
  let token = getOktaDetails();
  if (!token) {
    return { email: '', userName: '' };
  }
  const { email, name } = token?.idToken?.claims;
  return { email: email, userName: name };
}

export const apiResponse = async (method: string, url: string, urlIdentifier: any, payload: any) => {
  const oktaToken = getOktaToken();
  let finalUrl = '';
  if (urlIdentifier.length > 0) {
    if (urlIdentifier.length > 1) {
      finalUrl = `${getApiUrl(url)}${urlIdentifier[0]}&${urlIdentifier[1]}`;
    } else {
      finalUrl = `${getApiUrl(url)}${urlIdentifier[0]}`;
    }
  } else {
    finalUrl = getApiUrl(url);
  }

  const res = await axios({
    method: method,
    url: finalUrl,
    data: payload,
    headers: {
      Authorization: `Bearer ${oktaToken}`,
    },
  });
  return res;
};

export const pa_apiResponse = async () => {
  let finalUrl = process.env.REACT_APP_PA_API_LINK + 'api/v2/cache/brand';
  const res = await axios({
    method: 'get',
    url: finalUrl,
  });
  return res;
};

export const testCaseGen_apiResponse = async (apiMethod: string, payload: any, endpoint: string) => {
  const oktaToken = getOktaToken();
  let finalUrl = process.env.REACT_APP_TEST_CASE_GEN_API_LINK + endpoint;
  const res = await axios({
    method: apiMethod,
    url: finalUrl,
    data: payload,
    // headers: {
    //   Authorization: `Bearer ${oktaToken}`,
    // },
  });
  return res;
};

export const profileMatamo = (visitorId: string, matamoType: string, matamoDate: string) => {
  let matamoLink =
    'https://matomo-ext.devops.amgen.com/index.php?module=Widgetize&action=iframe&moduleToWidgetize=Live&actionToWidgetize=getVisitorProfilePopup&idSite=' +
    process.env.REACT_APP_MATAMO_ID +
    '&visitorId=' +
    visitorId;

  if (matamoType === 'range') {
    matamoLink += '&period=range&date=' + matamoDate;
  } else if (matamoType === 'day') {
    matamoLink += '&period=day&date=' + matamoDate;
  }
  window.open(matamoLink, '_blank');
};

export const workDayRedirect = (link: string) => {
  window.open(link, '_blank');
};

export const filterMembers = (usageTrackingData: any, userGroupValue: string, includeCoreMember: string) => {
  let dataArray = usageTrackingData.filter((obj: any) => {
    if (userGroupValue.toLowerCase() === 'all sensing users' && includeCoreMember === 'N') {
      return obj.group_name.toLowerCase() === userGroupValue.toLowerCase() && obj.core_member_flag === includeCoreMember;
    } else {
      return obj.group_name.toLowerCase() === userGroupValue.toLowerCase();
    }
  });

  return dataArray;
};

type AnyObject = {
  [key: string]: any;
};

function snakeToCamel(s: string): string {
  return s.replace(/(_\w)/g, matches => matches[1].toUpperCase());
}

export function convertObjectKeysToCamelCase(obj: AnyObject): AnyObject {
  const result: AnyObject = {};

  for (const key in obj) {
    const camelKey = snakeToCamel(key);

    if (obj[key] !== null && typeof obj[key] === 'object') {
      if (Array.isArray(obj[key])) {
        result[camelKey] = obj[key].map((element: AnyObject) => {
          if (typeof element === 'object' && element !== null) {
            return convertObjectKeysToCamelCase(element);
          } else {
            return element;
          }
        });
      } else {
        result[camelKey] = convertObjectKeysToCamelCase(obj[key]);
      }
    } else {
      result[camelKey] = obj[key];
    }
  }

  return result;
}

export function sortReleaseNotes(notes: IReleaseNoteItem[]) {
  return notes.sort((a, b) => {
    // Compare release version number
    const versionCmp = a.releaseVersionNumber.localeCompare(b.releaseVersionNumber, undefined, {
      numeric: true,
      sensitivity: 'base',
    });
    if (versionCmp !== 0) {
      return versionCmp;
    }

    // Compare release version date
    const dateCmp = new Date(a.releaseVersionDate).getTime() - new Date(b.releaseVersionDate).getTime();
    if (dateCmp !== 0) {
      return dateCmp;
    }

    // Compare workstream alphabetically
    return a.workstream.localeCompare(b.workstream);
  });
}

export function arraysOfObjectsAreEqual(arr1: AnyObject[], arr2: AnyObject[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (const obj1 of arr1) {
    const matchingObjIndex = arr2.findIndex(obj2 => objectsAreEqual(obj1, obj2));

    if (matchingObjIndex === -1) {
      return false;
    }

    arr2.splice(matchingObjIndex, 1);
  }

  return true;
}

function objectsAreEqual(obj1: AnyObject, obj2: AnyObject): boolean {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

function deepEqual(a: any, b: any): boolean {
  if (a === b) {
    return true;
  }

  if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
    return false;
  }

  const keysA = Object.keys(a);
  const keysB = Object.keys(b);

  if (keysA.length !== keysB.length) {
    return false;
  }

  for (const key of keysA) {
    if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
      return false;
    }
  }

  return true;
}

export function validateVersionFormat(version: string): boolean {
  const regex = /^[0-9]+\.[0-9]+\.[0-9]+$/;
  return regex.test(version);
}

export function validateDateFormat(date: string): boolean {
  const regex = /^[a-zA-Z]+ [0-9]{1,2}, [0-9]{4}$/;
  return regex.test(date);
}

export function convertCamelCaseToTitleCase(inputString: string): string {
  const words = inputString.split(/(?=[A-Z])/);
  const titleCaseWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const titleCaseString = titleCaseWords.join(' ');
  return titleCaseString;
}

export const formatMonthDayYearToMMDDYY = (dateString: string): string => {
  const date = new Date(dateString);
  if (!date) throw new Error('formatMonthDayYearToMMDDYY expects a date string as an input');
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}-${year}`;
};

export function hyphenToCamel(hyphenated: string): string {
  return hyphenated.replace(/([-_]\w)/g, m => m[1].toUpperCase());
}

export function camelToHyphen(camelCase: string): string {
  return camelCase.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
}

export const formatMonthDayYearToMMDDYYDash = (dateString: string): string => {
  const date = dateString.indexOf('-') > -1 ? dateString.split('-') : '';
  if (date === '') {
    return dateString;
  }

  const month = date[1];
  const day = date[2];
  const year = date[0];
  return `${month}-${day}-${year}`;
};

export const stringToHTMLFormatter = (str: string) => {
  const regHTML = new RegExp('<("[^"]*"|\'[^\']*\'|[^\'">])*>', 'g');
  const urlRegex = /https?:\/\/[^\s]+/g;

  if (regHTML.test(str)) {
  } else {
    str = str.replaceAll('\n', '<br>');
  }
  str = str.replace(urlRegex, url => `<a href="${url}" target="_blank" className='jira-links-ipt'>${url}</a>`);

  return str;
};
